import { Map } from "mapbox-gl";
import { useEffect } from "react";
import InteractiveMap from "react-map-gl";
import iconDefs from "../generated/iconSizes.json";

export const PREVIEW_IMAGE_WIDTH = 1200;

export function loadMapImage(map: Map, imageId: string) {
  return new Promise<void>((resolve, reject) => {
    if (map.hasImage(imageId)) resolve();

    const mirrored = !!imageId.match(/_mirrored$/);
    const originalImageId = imageId.replace(/_mirrored$/, "");

    if (iconDefs[originalImageId] && !map.hasImage(imageId)) {
      map.addImage(
        imageId,
        new ImageData(
          iconDefs[originalImageId].width,
          iconDefs[originalImageId].height
        ),
        { pixelRatio: 3.937007874 }
      );
    }

    const image = new Image();

    image.addEventListener("load", () => {
      let data: HTMLImageElement | ImageData = image;

      if (mirrored) {
        const canvas = document.createElement("canvas");
        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;
        const ctx = canvas.getContext("2d");

        ctx.save();
        ctx.scale(-1, 1);
        ctx.drawImage(image, -canvas.width, 0, canvas.width, canvas.height);
        ctx.restore();
        data = ctx.getImageData(0, 0, canvas.width, canvas.height);
      }

      if (iconDefs[originalImageId]) {
        // @ts-ignore-line
        map.updateImage(imageId, data);
      } else {
        map.addImage(imageId, data, {
          pixelRatio: 3.937007874,
        });
      }

      resolve();
    });
    image.addEventListener("error", reject);
    image.src = `/static/icons/${originalImageId}.svg`;
  });
}

export const useImageLoader = (mapObj: InteractiveMap) =>
  useEffect(() => {
    if (!mapObj) return;

    const map = mapObj.getMap();

    if (!map) return;

    function onImageMissing(e) {
      loadMapImage(map, e.id);
    }

    map.on("styleimagemissing", onImageMissing);

    return () => {
      map.off("styleimagemissing", onImageMissing);
    };
  }, [mapObj]);

export function loadImage(src: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.addEventListener("load", () => {
      resolve(img);
    });
    img.addEventListener("error", reject);
  });
}
