import { FeatureCollection, Point } from "geojson";
import get from "lodash/get";
import { GeoJSONSourceRaw, Layer } from "mapbox-gl";
import { BACKGROUND_COLORS } from "../constants/map";

export function styleReducer(state = {} as mapboxgl.Style, action) {
  const source = state.sources.placedIcons as GeoJSONSourceRaw;
  const data = source.data as FeatureCollection<Point>;

  switch (action.type) {
    case "UPDATE_PLACED_ICONS":
      return {
        ...state,
        sources: {
          ...state.sources,
          placedIcons: {
            ...source,
            data: {
              ...(source.data as FeatureCollection),
              features: action.features
            }
          }
        }
      };
    case "RESET_PLACED_ICONS":
      return {
        ...state,
        sources: {
          ...state.sources,
          placedIcons: {
            ...source,
            data: {
              ...(source.data as FeatureCollection),
              features: []
            }
          }
        }
      };
    case "ADD_POINT":
      return {
        ...state,
        sources: {
          ...state.sources,
          placedIcons: {
            ...source,
            data: {
              ...data,
              features: [...data.features, action.feature]
            }
          }
        }
      };
    case "REMOVE_POINT":
      return {
        ...state,
        sources: {
          ...state.sources,
          placedIcons: {
            ...source,
            data: {
              ...data,
              features: data.features.filter(f => f.properties.id !== action.id)
            }
          }
        }
      };
    case "SYNC_ACTIVE_ICON":
      return {
        ...state,
        sources: {
          ...state.sources,
          placedIcons: {
            ...source,
            data: {
              ...data,
              features: data.features.map(feature =>
                feature.properties.id === action.id
                  ? {
                      ...action.feature,
                      properties: {
                        ...action.feature.properties,
                        priority: new Date().getTime()
                      }
                    }
                  : feature
              )
            }
          },
          activeIcon: {
            ...state.sources.activeIcon,
            data: action.feature
          }
        }
      };
    case "HIDE_SOURCE_ICON_LAYERS":
      return {
        ...state,
        layers: state.layers.map<Layer>(layer =>
          get(layer, 'metadata["kinderkiez:type"]') === "MOVABLE"
            ? {
                ...layer,
                layout: {
                  ...layer.layout,
                  visibility: "none"
                }
              }
            : layer
        )
      };
    case "SHOW_SOURCE_ICON_LAYERS":
      return {
        ...state,
        layers: state.layers.map<Layer>(layer =>
          get(layer, 'metadata["kinderkiez:type"]') === "MOVABLE"
            ? {
                ...layer,
                layout: {
                  ...layer.layout,
                  visibility: "visible"
                }
              }
            : layer
        )
      };
    case "SET_STREET_LABEL_SPACING":
      return dynamicStyle(state, {
        streetLabelSpacing: action.spacing
      });
    case "SET_BACKGROUND_COLOR":
      return dynamicStyle(state, {
        backgroundColor: action.color
      });
    case "SET_PATHS_HIDDEN":
      return dynamicStyle(state, {
        hidePaths: action.hidden
      });
    case "APPLY_OVERRIDES":
      return action.overrides ? dynamicStyle(state, action.overrides) : state;
    case "RESET":
      return action.style;
    default:
      return state;
  }
}

// Selectors

export function getStyleFeatures(style: mapboxgl.Style) {
  const source = style.sources.placedIcons as GeoJSONSourceRaw;

  return (source.data as FeatureCollection<Point>).features;
}

export function getStyleFeatureIdById(style: mapboxgl.Style, id: string) {
  const source = style.sources.placedIcons as GeoJSONSourceRaw;

  return (source.data as FeatureCollection<Point>).features.findIndex(
    f => f.properties.id === id
  );
}

export interface Overrides {
  streetLabelSpacing?: number;
  backgroundColor?: string;
  hidePaths?: boolean;
}

export function getStyleOverrides(style: mapboxgl.Style): Overrides {
  return {
    streetLabelSpacing: get(
      style.metadata,
      "kinderkiez:streetLabelSpacing",
      700
    ),
    backgroundColor: get(
      style.metadata,
      "kinderkiez:backgroundColor",
      BACKGROUND_COLORS.stadtmausgrau
    ),
    hidePaths: get(style.metadata, "kinderkiez:hidePaths", false)
  };
}

export function filterByPrefill(
  prefill: string = "FULL",
  style: mapboxgl.Style
): mapboxgl.Style {
  if (prefill === "FULL") return style;

  return {
    ...style,
    layers: style.layers.filter(layer => {
      if (!layer.metadata) return true;

      switch (prefill) {
        case "EMPTY":
          return !layer.metadata["kinderkiez:priority"];
        case "ESSENTIALS":
          return (
            !layer.metadata["kinderkiez:priority"] ||
            layer.metadata["kinderkiez:priority"] === 2
          );
        default:
          return true;
      }
    })
  };
}

export function dynamicStyle(
  style: mapboxgl.Style,
  { streetLabelSpacing, backgroundColor, hidePaths }: Overrides
): mapboxgl.Style {
  return {
    ...style,
    metadata: {
      ...style.metadata,
      "kinderkiez:streetLabelSpacing":
        streetLabelSpacing || style.metadata["kinderkiez:streetLabelSpacing"],
      "kinderkiez:backgroundColor":
        backgroundColor || style.metadata["kinderkiez:backgroundColor"],
      "kinderkiez:hidePaths":
        typeof hidePaths !== "undefined"
          ? hidePaths
          : style.metadata["kinderkiez:hidePaths"]
    },
    layers: style.layers.map(layer => {
      if (
        hidePaths !== undefined &&
        layer.metadata &&
        layer.metadata["kinderkiez:type"] === "PATH"
      ) {
        layer.layout.visibility = hidePaths ? "none" : "visible";
      }

      switch (layer.id) {
        case "street-labels":
          return streetLabelSpacing
            ? {
                ...layer,
                layout: {
                  ...layer.layout,
                  "symbol-spacing": streetLabelSpacing
                }
              }
            : layer;
        case "background":
          return backgroundColor
            ? {
                ...layer,
                paint: {
                  ...layer.paint,
                  "background-color": backgroundColor
                }
              }
            : layer;
      }
      return layer;
    })
  };
}
