import cn from "classnames";
import { ButtonHTMLAttributes, FunctionComponent } from "react";
import styles from "./Button.css";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  expanded?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  alert?: boolean;
  pulse?: boolean;
  round?: boolean;
  outlined?: boolean;
  size?: "tiny" | "small" | "medium" | "large";
}

export const Button: FunctionComponent<Props> = ({
  children,
  expanded,
  secondary,
  tertiary,
  alert,
  pulse,
  round,
  className,
  outlined,
  size = "medium",
  ...props
}) => (
  <button
    className={cn([
      styles.button,
      expanded && styles.expanded,
      secondary && styles.secondary,
      tertiary && styles.tertiary,
      alert && styles.alert,
      pulse && styles.pulse,
      outlined && styles.outlined,
      size === "tiny" && styles.tiny,
      size === "small" && styles.small,
      size === "large" && styles.large,
      round && styles.round,
      className,
    ])}
    {...props}
  >
    {children}
  </button>
);
