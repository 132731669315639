import { FeatureCollection, Point } from "geojson";

export const SOURCE_LAYERS = [
  "item-buildings",
  "item-pois_type",
  "item-pois_type--priority",
  "item-pois_maki",
  "item-pois_maki--priority",
  "item-airports",
  "item-airplanes",
  "item-trainstops"
];

export interface Configuration extends FeatureCollection<Point> {
  properties: { [key: string]: any };
}

export const BACKGROUND_COLORS = {
  stadtmausgrau: "#d5dedf",
  weichesweiss: "#f3eee6",
  wuestengelb: "#ffecb3",
  schweinchenrosa: "#fce4ec",
  froebelgruen: "#c8e6c9",
  nachtblau: "#455a64"
};
