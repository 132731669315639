import { useEffect } from "react";

export function useViewportUnits() {
  useEffect(() => {
    function updateUnits() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    updateUnits();

    window.addEventListener("resize", updateUnits);

    return () => {
      window.removeEventListener("resize", updateUnits);
    };
  }, []);
}
