import { FunctionComponent, ReactNode } from "react";
import styles from "./Layout.css";

interface Props {
  sidebar?: ReactNode;
  header?: ReactNode;
}

export const Layout: FunctionComponent<Props> = ({
  children,
  sidebar,
  header
}) => {
  return (
    <div className={styles.container}>
      {!!header && <header className={styles.header}>{header}</header>}
      <div className={styles.contentContainer}>
        <main className={styles.content}>{children}</main>
        {!!sidebar && <div className={styles.sidebar}>{sidebar}</div>}
      </div>
    </div>
  );
};
