export interface Format {
  width: number;
  height: number;
  displayWidth: number;
  displayHeight: number;
  realWidth: number;
  realHeight: number;
  price: number;
  round?: boolean;
  ecommerceId: string;
  poster?: boolean;
  shopifyId: number;
}

/**
 * 1cm = 10.24px
 */
export const FORMATS: Record<string, Format> = {
  // SMALL: {
  //   width: 1024,
  //   height: 716.8,
  //   displayWidth: 100,
  //   displayHeight: 70,
  //   realWidth: 100,
  //   realHeight: 70,
  //   price: 109.0,
  //   ecommerceId: "74",
  //   shopifyId: 39338816700438,
  // },
  MEDIUM: {
    width: 1433.6,
    height: 1024,
    displayWidth: 140,
    displayHeight: 100,
    realWidth: 140,
    realHeight: 100,
    price: 219.0,
    ecommerceId: "73",
    shopifyId: 42200002560022,
  },
  LARGE: {
    width: 2048,
    height: 1433.6,
    displayWidth: 200,
    displayHeight: 140,
    realWidth: 200,
    realHeight: 140,
    price: 349.0,
    ecommerceId: "39",
    shopifyId: 42200002592790,
  },
  // ROUND_MEDIUM: {
  //   width: 1024,
  //   height: 1024,
  //   displayWidth: 100,
  //   displayHeight: 100,
  //   realWidth: 100,
  //   realHeight: 100,
  //   price: 179.0,
  //   round: true,
  //   ecommerceId: "876",
  //   shopifyId: 39338816733206,
  // },
  // ROUND_LARGE: {
  //   width: 1433.6,
  //   height: 1433.6,
  //   displayWidth: 140,
  //   displayHeight: 140,
  //   realWidth: 140,
  //   realHeight: 140,
  //   price: 299.0,
  //   round: true,
  //   ecommerceId: "878",
  //   shopifyId: 39327068389398,
  // },
  // PANORAMA_POSTER_MEDIUM: {
  //   width: 2048,
  //   height: 1433.6,
  //   displayWidth: 70,
  //   displayHeight: 50,
  //   realWidth: 200,
  //   realHeight: 140,
  //   price: 29.0,
  //   ecommerceId: "2747",
  //   poster: true,
  //   shopifyId: 39329593196566,
  // },
  // PANORAMA_POSTER_LARGE: {
  //   width: 2048,
  //   height: 1433.6,
  //   displayWidth: 100,
  //   displayHeight: 70,
  //   realWidth: 200,
  //   realHeight: 140,
  //   price: 39.0,
  //   ecommerceId: "2751",
  //   poster: true,
  //   shopifyId: 39329475526678,
  // },
  // CLOSEUP_POSTER_MEDIUM: {
  //   width: 1433.6,
  //   height: 1024,
  //   displayWidth: 70,
  //   displayHeight: 50,
  //   realWidth: 140,
  //   realHeight: 100,
  //   price: 29.0,
  //   ecommerceId: "2749",
  //   poster: true,
  //   shopifyId: 39338816667670,
  // },
  // CLOSEUP_POSTER_LARGE: {
  //   width: 1433.6,
  //   height: 1024,
  //   displayWidth: 100,
  //   displayHeight: 70,
  //   realWidth: 140,
  //   realHeight: 100,
  //   price: 39.0,
  //   ecommerceId: "2750",
  //   poster: true,
  //   shopifyId: 39338816634902,
  // },
};

export type FormatId = Extract<keyof typeof FORMATS, string>;
